
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import LsDialog from "@/components/ls-dialog.vue";
import LsMaterial from "./material.vue";
import FileItem from "./file-item.vue";
import Draggable from "vuedraggable";

@Component({
    components: {
        LsDialog,
        LsMaterial,
        Draggable,
        FileItem,
    },
})
export default class MediaSelect extends Vue {
    $refs!: { materialDialog: any; material: any };
    select!: any[];
    currentIndex!: number;
    // 双向数据绑定的值
    @Prop({ default: () => [] }) value!: [] | string;
    // 图片张数限制
    @Prop({ default: 1 }) limit!: number;
    @Prop({ default: "100" }) size!: string;
    @Prop({ default: false }) disabled!: boolean; //禁用图片选择
    @Prop({ default: false }) dragDisabled!: boolean; //禁用图片拖拽
    @Prop({ default: false }) hiddenTrigger!: boolean; //是否隐藏
    @Prop({ default: "image" }) type!: "image" | "video"; //是否隐藏
    @Prop({ default: "transparent" }) uploadBg!: string;
    @Prop({ default: true }) enableDomain!: boolean;
    @Prop({ default: true }) enableDelete!: boolean;
    isAdd = true;
    fileList: any[] = [];

    get showUpload() {
        const { fileList, limit } = this;
        return limit - fileList.length > 0;
    }

    get meterialLimit() {
        if (!this.isAdd) {
            return 1;
        }
        if (!this.limit) return null;
        return this.limit - this.fileList.length;
    }

    get tipsText() {
        switch (this.type) {
            case "image":
                return "图片";
            case "video":
                return "视频";
        }
    }
    get imageUri() {
        return (item: string) => {
            return this.enableDomain ? item : this.$getImageUri(item);
        };
    }
    @Watch("value", { immediate: true })
    valueChange(val: any[] | string) {
        this.fileList = Array.isArray(val) ? val : val == "" ? [] : [val];
    }
    showDialog(isAdd: boolean = true, index: number) {
        if (this.disabled) return;
        this.isAdd = isAdd;
        if (index !== undefined) {
            this.currentIndex = index;
        }
        this.$refs.materialDialog?.onTrigger();
    }
    selectChange(val: any[]) {
        this.select = val;
    }
    handleConfirm() {
        this.$refs.material.clearSelectList();
        const selectUri = this.select.map((item) =>
            this.enableDomain ? item.uri : item.url
        );
        if (!this.isAdd) {
            this.fileList.splice(this.currentIndex, 1, selectUri.shift());
        } else {
            this.fileList = this.fileList.concat(selectUri);
        }
        this.handleChange();
    }

    delImage(index: number) {
        this.fileList.splice(index, 1);
        this.handleChange();
    }
    handleChange() {
        const valueImg =
            this.limit != 1 ? this.fileList : this.fileList[0] || "";
        this.$emit("input", valueImg);
        this.$emit("change", valueImg);
        this.fileList = [];
    }
}
